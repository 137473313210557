import React, { useState } from "react";
import { Player } from "video-react";
import {
  Container,
  Row,
  Col,
  Button,
  Accordion,
  Card,
  Modal,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlayCircle } from "@fortawesome/free-solid-svg-icons";

const LoginForm = ({ onSubmit }) => {
  return (
    <div className="_button-wrapper _full_width">
      <Player
        poster="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/commitment-mastery/tutorial-cara-beli-fix.jpg"
        src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/commitment-mastery/video/video-cara-daftar.mp4"
      />
    </div>
  );
};

export default function Secfaq() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const onLoginFormSubmit = (e) => {
    e.preventDefault();
    handleClose();
  };

  return (
    <>
      <Container fluid className="__bg-secondary-sl">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="__wrappe-headline-sl">
                <h2 className="text-center mb-5">F A Q</h2>
              </div>
              <Accordion defaultActiveKey="0">
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="0">
                      <p className="text-dark __text-center-faq">
                        {" "}
                        1. Maksudnya membership VIP ALL Access apa ya?{" "}
                      </p>
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <p>
                        Membership VIP adalah kategori membership di platform
                        Trulav.id dimana kamu bisa mengakses semua kategori
                        course mulai dari FREE, Premium dan VIP. <br />
                        <br /> Hingga saat ini ada lebih dari 100+ video course
                        yang tersedia di Trulav.id <br />
                        <br />
                        Dengan join VIP membership, kamu bisa mengakses ratusan
                        video course dengan harga yang jauh lebih murah daripada
                        membeli satuan. Plus keuntungan fasilitas lainnya
                        seperti support system dan bisa ikutan Exclusive Webinar
                        GRATIS tanpa perlu biaya tambahan.
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="1">
                      <p className="text-dark __text-center-faq">
                        2. Apa yang terjadi setelah pembayaran saya berhasil?
                      </p>
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="1">
                    <Card.Body>
                      <p>
                        Kamu akan menerima email berupa akses ke akun membership
                        Trulav milikmu. Jika kamu sudah punya akun Trulav
                        sebelumnya, kamu bisa langsung login untuk mengakses
                        video course yang kamu inginkan.
                      </p>
                      {/* <p>
                        Mempraktekkan teknik-teknik di commitment mastery yang
                        terukur adalah hal yang bisa kamu kendalikan. Sedangkan
                        keputusan apakah dia memilih untuk berkomitmen padamu
                        adalah hal yang tidak bisa kamu kendalikan.
                      </p>
                      <p>
                        Fokus pada apa yang bisa kita kendalikan akan membuat
                        dirimu jauh lebih tenang, happy dan gak terjebak di
                        hubungan yang tanpa arah. And THAT’S THE KEY!
                      </p> */}
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="2">
                      <p className="text-dark __text-center-faq">
                        {" "}
                        3. Bagaimana jika membership saya habis?
                      </p>
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="2">
                    <Card.Body>
                      <p>
                        Setelah durasi membershipmu habis, kamu bisa lakukan
                        perpanjangan dengan harga normal.
                      </p>
                      {/* <p>
                        Kira-kira mana kondisi yang lebih kamu sukai? Belum ada
                        gebetan tapi sudah tau ilmunya jadi bisa praktek
                        kapanpun kamu mau ketika sudah punya pasangan nanti,
                        atau.. nunggu ditikung dan gebetanmu atau pasanganmu
                        terlanjur memilih wanita lain karena kamu dianggap
                        terlalu menuntut?
                      </p>
                      <p>
                        {" "}
                        Jadilah wanita cerdas yang menguasai tekniknya bahkan
                        dari sebelum punya pasangan.. :)
                      </p> */}
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="3">
                      <p className="text-dark __text-center-faq">
                        {" "}
                        {/* Apakah Commitment Mastery bisa saya gunakan untuk
                        membuat dia membuktikan keseriusan berkomitmennya kepada
                        saya?{" "} */}
                        4. Kapan promo THR ini berakhir?
                      </p>
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="3">
                    <Card.Body>
                      <p>Promo ini berakhir tanggal 26 April pukul 20:00 WIB</p>
                      {/* <p>
                        Seluruh teknik yang akan kamu pelajari bisa menuntun dan
                        mengarahkan kamu untuk membuktikan apakah dia pria yang
                        tepat untuk diajak komitmen atau tidak.
                      </p>
                      <p>
                        Tetapi kalau memang hasil akhirnya dia tetap tidak mau
                        berkomitmen denganmu, setidaknya kamu paham memang bukan
                        dia yang pantas untuk wanita cerdas seperti kamu :)
                      </p> */}
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="4">
                      <p className="text-dark __text-center-faq">
                        5. Bagaimana jika saya sudah memiliki membership
                        sebelumnya?
                        {/* {" "}
                        Jika saya berhalangan hadir, apakah ada rekaman
                        trainingnya?{" "} */}
                      </p>
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="4">
                    <Card.Body>
                      <p>
                        Membership kamu akan terupdate sesuai durasi membership
                        yang kamu pilih
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="5">
                      <p className="text-dark __text-center-faq">
                        6. Apakah ada nomor WhatsApp yang bisa saya hubungi?
                        {/* {" "}
                        Apakah online trainingnya bisa diakses via HP?{" "} */}
                      </p>
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="5">
                    <Card.Body>
                      <p>
                        Kamu bisa hubungi tim kami via WhatsApp 0812-1913-5268
                        (Fakhri) Atau email di support@trulav.id
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                {/* <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="6">
                      <p className="text-dark __text-center-faq">
                        Mengapa saya perlu segera daftar sekarang?{" "}
                      </p>
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="6">
                    <Card.Body>
                      <p>
                        ​​​​Dalam skala 1-10, level kelangkaan training ini
                        berada di level 9.{" "}
                      </p>
                      <p>
                        Selain harus menunggu 1 tahun lagi untuk batch
                        berikutnya, pendaftaran online training ini ditutup
                        sewaktu-waktu tanpa pemberitahuan sebelumnya.
                      </p>
                      <p>
                        Mendengar fakta ini, kami yakin, kamu tidak perlu
                        ditakut-takuti untuk segera daftar sekarang.
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="7">
                      <p className="text-dark __text-center-faq">
                        Wah jadi gak sabar mau join Commitment Mastery!{" "}
                      </p>
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="7">
                    <Card.Body>
                      <p>
                        ​Yes, saran kami.. Segera putuskan daftar sebelum waktu
                        habis (KARENA CUMA 5 HARI) dan kamu menyesal perlu
                        menunggu lebih lama untuk batch berikutnya
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="8">
                      <p className="text-dark __text-center-faq">
                        Masih ada pertanyaan?{" "}
                      </p>
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="8">
                    <Card.Body>
                      <p>
                        ​Email kami di cs@joseaccelerated.com atau via
                        WhatsApp:+62 812-8114-5328 Gilang & WhatsApp:
                        081219135268 Fakhri
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card> */}
              </Accordion>
            </Col>
          </Row>
          {/* <Row>
            <Col lg={12}>
              <div className="__tutorial-center-text">
                <h2>Tutorial Cara Beli</h2>
              </div>
            </Col>
            <Col lg={12}>
              <div className="d-flex justify-content-center">
                <div className="__bg-thumbnail-video-tutor">
                  <div className="d-flex justify-content-center">
                    <FontAwesomeIcon
                      icon={faPlayCircle}
                      className="__fa-video-circle"
                      style={{ color: "red" }}
                      onClick={handleShow}
                    />
                  </div>
                </div>
              </div>
            </Col>
          </Row> */}
        </Container>
      </Container>

      <Modal show={show} onHide={handleClose} size="md" centered>
        <Modal.Header closeButton>
          <Modal.Title className="__text-wl-title">Video Cara Beli</Modal.Title>
        </Modal.Header>
        <Modal.Body className="__modal-body-bg">
          <LoginForm onSubmit={onLoginFormSubmit} />
        </Modal.Body>
      </Modal>
    </>
  );
}
